import { Col, Description, MarkerImg, Title, Wrapper } from "./styles";

function MainTitle() {
  return (
    <Wrapper>
      <Col>
        <Title>Unlocking Company Insight</Title>
        <Description>성공을 위한 맞춤형 로드맵 탐색</Description>
        <Description>당신을 위한 개발자 커리어 플랫폼</Description>
      </Col>
      <Col>
        <MarkerImg
          width="181"
          height="177"
          viewBox="0 0 181 177"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_16_401)">
            <path
              d="M79.8684 0C65.71 0 53.8115 11.7063 53.8115 26.2646C53.8115 39.6821 75.0528 71.6582 77.6012 75.0807C78.7329 76.7919 81.0002 76.7919 82.132 75.0807C84.6804 71.3712 105.922 39.3951 105.922 26.2646C105.644 11.7063 94.0305 0 79.8684 0ZM79.8684 68.5154C72.5044 57.0962 59.4778 35.1152 59.4778 26.2646C59.4778 14.8454 68.5395 5.71146 79.8684 5.71146C91.1973 5.71146 100.259 14.8454 100.259 26.2646C100.259 34.8281 87.2324 57.0962 79.8684 68.5154Z"
              fill="#EAEAEA"
            />
            <path
              d="M79.8684 12.2767C73.0703 12.2767 67.4077 17.9882 67.4077 24.8368C67.4077 31.6854 73.074 37.3968 79.8684 37.3968C86.6628 37.3968 92.3291 31.6854 92.3291 24.8368C92.3291 17.9882 86.6628 12.2767 79.8684 12.2767ZM79.8684 31.689C75.9035 31.689 72.7892 28.5499 72.7892 24.5534C72.7892 20.5568 75.9035 17.4178 79.8684 17.4178C83.8333 17.4178 86.9476 20.5568 86.9476 24.5534C86.6628 28.5499 83.5486 31.689 79.8684 31.689Z"
              fill="#EAEAEA"
            />
            <path
              d="M155.491 76.2252C141.329 76.2252 129.434 87.9314 129.434 102.49C129.434 109.342 134.815 120.761 140.478 131.04L133.68 124.187C132.548 123.047 130.847 123.047 129.715 124.187C128.583 125.328 128.583 127.043 129.715 128.184L137.364 135.894C138.495 137.035 140.197 137.035 141.329 135.894C141.895 135.323 142.179 134.753 142.179 134.182C143.311 136.181 144.447 137.892 145.578 139.607H145.294C144.162 140.748 144.162 142.463 145.294 143.603L152.942 151.313C154.074 152.741 156.341 152.454 157.192 151.026C159.74 147.317 180.982 115.341 180.982 102.21C181.548 88.2222 169.938 76.2325 155.491 76.2325V76.2252ZM155.491 144.741C148.127 133.321 135.1 111.34 135.1 102.49C135.1 91.0705 144.162 81.9366 155.491 81.9366C166.82 81.9366 175.881 91.0705 175.881 102.49C176.166 111.34 162.855 133.608 155.491 144.741Z"
              fill="#EAEAEA"
            />
            <path
              d="M155.491 88.7852C148.693 88.7852 143.03 94.4967 143.03 101.345C143.03 108.194 148.696 113.905 155.491 113.905C162.285 113.905 167.951 108.194 167.951 101.345C167.951 94.4967 162.57 88.7852 155.491 88.7852ZM155.491 108.198C151.526 108.198 148.412 105.058 148.412 101.062C148.412 97.0653 151.526 93.9263 155.491 93.9263C159.456 93.9263 162.57 97.0653 162.57 101.062C162.57 105.058 159.456 108.198 155.491 108.198Z"
              fill="#EAEAEA"
            />
            <path
              d="M26.3417 113.052C19.5436 113.052 13.881 118.763 13.881 125.612C13.881 132.46 19.5472 138.172 26.3417 138.172C33.1361 138.172 38.8024 132.46 38.8024 125.612C38.8024 118.763 33.1361 113.052 26.3417 113.052ZM26.3417 132.751C22.3767 132.751 19.2625 129.612 19.2625 125.615C19.2625 121.619 22.3767 118.48 26.3417 118.48C30.3066 118.48 33.4209 121.619 33.4209 125.615C33.1361 129.612 30.0218 132.751 26.3417 132.751Z"
              fill="#EAEAEA"
            />
            <path
              d="M36.5352 169.577L32.0043 170.435C38.8024 159.873 52.1138 137.605 52.1138 127.039C52.1138 112.764 40.5001 100.775 26.0569 100.775C11.6137 100.775 0 112.481 0 127.039C0 140.17 22.6579 175.285 24.0744 176.43C24.0744 176.43 24.6403 176.717 24.9251 177H26.9076L37.6706 175.002C39.0872 174.715 40.219 173.29 39.9379 171.863C39.372 170.151 37.9554 169.294 36.5388 169.577H36.5352ZM26.338 169.29C18.974 157.871 5.94741 135.89 5.94741 127.039C5.94741 115.62 15.0091 106.486 26.338 106.486C37.667 106.486 46.7286 115.62 46.7286 127.039C46.7286 135.89 33.4172 158.158 26.338 169.29Z"
              fill="#EAEAEA"
            />
            <path
              d="M79.5873 161.297L68.8242 163.295C67.4077 163.582 66.2759 165.007 66.557 166.435C66.8381 167.862 68.2547 169.003 69.6713 168.72L80.4343 166.722C81.8509 166.435 82.9827 165.01 82.7015 163.582C82.7015 162.155 81.285 161.014 79.5873 161.297Z"
              fill="#EAEAEA"
            />
            <path
              d="M58.0612 165.581L47.2982 167.579C45.8816 167.866 44.7498 169.29 45.0309 170.718C45.3121 172.146 46.7286 173.287 48.1452 173.003L58.9082 171.005C60.3248 170.718 61.4566 169.294 61.1755 167.866C60.8907 166.155 59.4778 165.297 58.0612 165.581Z"
              fill="#EAEAEA"
            />
            <path
              d="M144.447 149.024L133.684 151.309C132.267 151.597 131.135 153.021 131.416 154.449C131.697 155.876 133.114 157.017 134.531 156.734L145.294 154.736C146.71 154.449 147.842 153.024 147.561 151.597C147.276 149.885 145.863 148.741 144.447 149.028V149.024Z"
              fill="#EAEAEA"
            />
            <path
              d="M101.394 157.301L90.6314 159.299C89.2149 159.586 88.0831 161.01 88.3642 162.438C88.6453 163.866 90.0619 165.007 91.4784 164.723L102.241 162.725C103.658 162.438 104.79 161.014 104.509 159.586C104.228 158.158 102.811 157.017 101.394 157.301Z"
              fill="#EAEAEA"
            />
            <path
              d="M95.447 89.0722C96.5788 87.9314 96.5788 86.2165 95.447 85.0757L87.7983 77.366C86.6665 76.2251 84.9651 76.2251 83.8333 77.366C82.7015 78.5068 82.7015 80.2217 83.8333 81.3625L91.4821 89.0722C92.6139 90.2131 94.3153 90.2131 95.447 89.0722Z"
              fill="#EAEAEA"
            />
            <path
              d="M118.671 108.768C117.539 107.627 115.838 107.627 114.706 108.768C113.574 109.909 113.574 111.624 114.706 112.764L122.355 120.474C123.486 121.615 125.188 121.615 126.32 120.474C127.451 119.333 127.451 117.618 126.32 116.478L118.671 108.768Z"
              fill="#EAEAEA"
            />
            <path
              d="M110.741 100.775L103.092 93.0651C101.96 91.9243 100.259 91.9243 99.1272 93.0651C97.9954 94.2059 97.9954 95.9208 99.1272 97.0617L106.776 104.771C107.908 105.912 109.609 105.912 110.741 104.771C111.873 103.631 111.873 101.916 110.741 100.775Z"
              fill="#EAEAEA"
            />
            <path
              d="M122.921 153.304L112.157 155.302C110.741 155.589 109.609 157.014 109.89 158.441C110.171 159.869 111.588 161.01 113.005 160.727L123.768 158.728C125.184 158.441 126.316 157.017 126.035 155.589C125.75 153.878 124.337 153.021 122.921 153.304Z"
              fill="#EAEAEA"
            />
          </g>
          <defs>
            <clipPath id="clip0_16_401">
              <rect width="181" height="177" fill="white" />
            </clipPath>
          </defs>
        </MarkerImg>
      </Col>
    </Wrapper>
  );
}

export default MainTitle;
