import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";

import { ApiMessage, ApiStatus, UserLike } from "../../../constants/enums";
import { IRoadmapCourseVideos } from "../../../recoil/roadmapCourseDetail/atom";
import {
  AnchorWrapper,
  CourseActions,
  CourseLikeBtn,
  CourseLikeLabel,
  CourseLikeSvg,
  CourseLikeWrapper,
  CourseLogo,
  CourseLogoWrapper,
  CourseThumbnail,
  CourseThumbnailWrapper,
  CourseTitle,
  CourseTitleWrapper,
  CourseWrapper,
} from "./styles";
import { accessTokenState } from "../../../recoil/accessToken/atom";
import { postUserLikedYn } from "../../../services/apis";
import { isLoginState } from "../../../recoil/isLogin/atoms";
import { IUserLikedYn } from "../../../services/types";
import issueNewAccessTokenHook from "../../../utils/issueNewAccessTokenHook";

interface IOnClickLikeBtn {
  selectedVideoId: number;
  accessToken: string | null;
  recursionCount: number;
}

interface IRoadmapCourseItems {
  $isVideoTap: boolean;
  id: number;
  title: string;
  likeCnt?: number | null;
  thumbnail: string;
  url: string;
  userLikedYn?: string | null;
  source: string;
}

function CourseBox({
  $isVideoTap,
  id,
  title,
  likeCnt = null,
  thumbnail,
  url,
  userLikedYn = null,
  source,
}: IRoadmapCourseItems) {
  const history = useHistory();

  const [userLike, setUserLike] = useState(userLikedYn);

  const setIsLogin = useSetRecoilState(isLoginState);

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);

  const onClickLikeBtn = async ({
    selectedVideoId,
    accessToken,
    recursionCount,
  }: IOnClickLikeBtn) => {
    if (recursionCount > 3) {
      alert("과도한 통신량 발생. 관리자에게 문의해주세요.");
      setIsLogin(false);
      setAccessToken(null);
      localStorage.removeItem("refreshToken");
      history.push("/");
      return;
    }

    if (accessToken === null) {
      alert("로그인이 필요한 기능입니다.");
      return;
    }

    const UserLikeApiResponse: IUserLikedYn = await postUserLikedYn({
      selectedVideoId,
      accessToken,
    });

    if (UserLikeApiResponse.status === ApiStatus.error) {
      if (UserLikeApiResponse.message === ApiMessage.login_required) {
        alert("로그인이 필요한 서비스입니다.");
        setIsLogin(false);
        setAccessToken(null);
        localStorage.removeItem("refreshToken");
        history.push("/");
      } else {
        const newAccessToken: string | null = await issueNewAccessTokenHook();
        if (newAccessToken === "/") {
          setIsLogin(false);
          setAccessToken(null);
          history.push("/");
        } else {
          setAccessToken(newAccessToken);
          onClickLikeBtn({
            selectedVideoId,
            accessToken: newAccessToken,
            recursionCount: recursionCount + 1,
          });
        }
      }
      return;
    } else {
      setUserLike((prev) =>
        prev === UserLike.YES ? UserLike.NO : UserLike.YES
      );
    }
  };

  return (
    <CourseWrapper>
      <AnchorWrapper href={url} target="_blank">
        <CourseThumbnailWrapper>
          <CourseThumbnail
            src={thumbnail}
            alt={title}
            $isVideoTap={$isVideoTap}
          />
        </CourseThumbnailWrapper>
        <CourseTitleWrapper>
          <CourseTitle>{title}</CourseTitle>
        </CourseTitleWrapper>
      </AnchorWrapper>
      <CourseActions $isVideoTap={$isVideoTap}>
        {$isVideoTap ? (
          <CourseLikeWrapper>
            <CourseLikeBtn
              onClick={() =>
                onClickLikeBtn({
                  selectedVideoId: id,
                  accessToken,
                  recursionCount: 0,
                })
              }
            >
              <CourseLikeSvg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                $userLiked={userLike}
              >
                {userLike === UserLike.YES ? (
                  <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
                ) : (
                  <path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z" />
                )}
              </CourseLikeSvg>
            </CourseLikeBtn>
            {/* <CourseLikeLabel>{likeCnt}</CourseLikeLabel> */}
          </CourseLikeWrapper>
        ) : null}
        <CourseLogoWrapper>
          <CourseLogo>{source}</CourseLogo>
        </CourseLogoWrapper>
      </CourseActions>
    </CourseWrapper>
  );
}

export default CourseBox;
